<template>
    <div class="row-image">
        <v-row no-gutters>
            <v-col cols="12" md="4" lg="auto">
                <v-img :src="image" max-width="310" class="w-100 h-100 mx-auto"></v-img>
            </v-col>
            <v-col cols="12" md="8" lg="" class="row-image__info mt-10 mt-md-0 border border-md-start-0">
                <div class="w-100 h-100 pa-20 pa-md-24">
                    <h6 class="page-text page-text--lg grey-1--text">
                        {{tit}}
                    </h6>
                    <v-divider class="my-8 my-lg-16" />
                    <slot />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    props: {
        tit: { type: String, default: "" },
        image: { type: String, default: "" },
    },
    components: {
    },
    data: () => {
        return {};
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.row-image:not(:last-child){
    margin-bottom: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .row-image__info{
        max-width: calc(100% - 310px);
    }
}
@media (min-width:1200px){
}
</style>
