<template>
    <client-page>

        <sub-visual
        sh="후원하기"
        tabActive="후원 가이드"
        bg="/images/sub/visual/sv3.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <h4 class="tit tit--sm line-height-1 mb-20 mb-lg-40">
                    후원금 납입방법
                </h4>

                <div class="mb-40 mb-lg-60">
                    <row-image tit="자동이체" image="/images/sub/support/guide/s1-img1.jpg">
                        <div class="page-text">
                            <div class="d-flex mb-4 mb-lg-10">
                                <p class="primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2">
                                    출금날짜
                                </p>
                                <p>
                                    매월 10일 (계좌에 잔고가 부족할 경우 26일에 재확인 출금) <br>
                                    후원금 확인일(10일, 26일)이 토, 일, 공휴일인 경우 익일 평일에 자동이체됩니다.
                                </p>
                            </div>
                            <div class="d-flex">
                                <p class="primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2">
                                    출금명
                                </p>
                                <p>
                                    위드캔0000 (뒤의 숫자는 후원 연도와 후원한 월에 대한 안내입니다.)
                                </p>
                            </div>
                        </div>
                    </row-image>
                    <row-image tit="신용카드" image="/images/sub/support/guide/s1-img2.jpg">
                        <div class="page-text">
                            <div class="d-flex mb-4 mb-lg-10">
                                <p class="primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2">
                                    결제날짜
                                </p>
                                <p>
                                    매월 10일 <br>
                                    후원금 확인일(10일)이 토, 일, 공휴일인 경우 익일 평일에 결제됩니다.
                                </p>
                            </div>
                            <div class="d-flex">
                                <p class="primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2">
                                    결제명
                                </p>
                                <p>
                                    나이스정보통신 또는 사회복지법인 위드캔복지재단 <br>
                                    (위드캔복지재단은 신용카드 가맹점으로 등록될 수 없어 결제대행업체를 통해 후원금이 결제됩니다.)
                                </p>
                            </div>
                        </div>
                    </row-image>
                    <row-image tit="지로납부" image="/images/sub/support/guide/s1-img3.jpg">
                        <div class="page-text">
                            <div class="d-flex mb-4 mb-lg-10">
                                <p class="primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2">
                                    납부날짜
                                </p>
                                <p>
                                    지로납부를 신청하신 회원님께 매달 MICR(후원금액 미기재) 지로를 보내드리고 있습니다.
                                </p>
                            </div>
                            <div class="d-flex">
                                <p class="primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2">
                                    납부명
                                </p>
                                <p>
                                    은행, 인터넷 뱅킹 및 인터넷 지로 사이트 (www.giro.or.kr)를 통해 납입
                                </p>
                            </div>
                        </div>
                    </row-image>
                </div>

                <v-card outlined>
                    <div class="pa-20 pa-md-24 grey-fa">
                        <h5 class="font-size-16 font-size-lg-20 line-height-1 mb-8 mb-lg-16">
                            위드캔복지재단 후원계좌
                        </h5>
                        <p class="page-text page-text--sm line-height-15 mb-4 mb-lg-8">
                            KB국민은행 027001-04-234141(비지정) 사회복지법인 위드캔복지재단
                        </p>
                        <p class="page-text page-text--sm line-height-15 mb-8 mb-lg-16">
                            KB국민은행 017037-04-003957(지정) 사회복지법인 위드캔복지재단
                        </p>
                        <p class="page-text page-text--sm line-height-15" style="color: #F19C6D">
                            (반드시 예금주를 확인하세요)
                        </p>
                    </div>
                </v-card>
                
            </v-container>
        </section>
        

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import RowImage from "@/components/client/sub/row-image.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        RowImage,
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
</style>