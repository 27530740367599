var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row-image"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-100 h-100 mx-auto",
    attrs: {
      "src": _vm.image,
      "max-width": "310"
    }
  })], 1), _c('v-col', {
    staticClass: "row-image__info mt-10 mt-md-0 border border-md-start-0",
    attrs: {
      "cols": "12",
      "md": "8",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 pa-20 pa-md-24"
  }, [_c('h6', {
    staticClass: "page-text page-text--lg grey-1--text"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]), _c('v-divider', {
    staticClass: "my-8 my-lg-16"
  }), _vm._t("default")], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }