var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "후원하기",
      "tabActive": "후원 가이드",
      "bg": "/images/sub/visual/sv3.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('h4', {
    staticClass: "tit tit--sm line-height-1 mb-20 mb-lg-40"
  }, [_vm._v(" 후원금 납입방법 ")]), _c('div', {
    staticClass: "mb-40 mb-lg-60"
  }, [_c('row-image', {
    attrs: {
      "tit": "자동이체",
      "image": "/images/sub/support/guide/s1-img1.jpg"
    }
  }, [_c('div', {
    staticClass: "page-text"
  }, [_c('div', {
    staticClass: "d-flex mb-4 mb-lg-10"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2"
  }, [_vm._v(" 출금날짜 ")]), _c('p', [_vm._v(" 매월 10일 (계좌에 잔고가 부족할 경우 26일에 재확인 출금) "), _c('br'), _vm._v(" 후원금 확인일(10일, 26일)이 토, 일, 공휴일인 경우 익일 평일에 자동이체됩니다. ")])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2"
  }, [_vm._v(" 출금명 ")]), _c('p', [_vm._v(" 위드캔0000 (뒤의 숫자는 후원 연도와 후원한 월에 대한 안내입니다.) ")])])])]), _c('row-image', {
    attrs: {
      "tit": "신용카드",
      "image": "/images/sub/support/guide/s1-img2.jpg"
    }
  }, [_c('div', {
    staticClass: "page-text"
  }, [_c('div', {
    staticClass: "d-flex mb-4 mb-lg-10"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2"
  }, [_vm._v(" 결제날짜 ")]), _c('p', [_vm._v(" 매월 10일 "), _c('br'), _vm._v(" 후원금 확인일(10일)이 토, 일, 공휴일인 경우 익일 평일에 결제됩니다. ")])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2"
  }, [_vm._v(" 결제명 ")]), _c('p', [_vm._v(" 나이스정보통신 또는 사회복지법인 위드캔복지재단 "), _c('br'), _vm._v(" (위드캔복지재단은 신용카드 가맹점으로 등록될 수 없어 결제대행업체를 통해 후원금이 결제됩니다.) ")])])])]), _c('row-image', {
    attrs: {
      "tit": "지로납부",
      "image": "/images/sub/support/guide/s1-img3.jpg"
    }
  }, [_c('div', {
    staticClass: "page-text"
  }, [_c('div', {
    staticClass: "d-flex mb-4 mb-lg-10"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2"
  }, [_vm._v(" 납부날짜 ")]), _c('p', [_vm._v(" 지로납부를 신청하신 회원님께 매달 MICR(후원금액 미기재) 지로를 보내드리고 있습니다. ")])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-60px min-w-lg-80px mr-lg-2"
  }, [_vm._v(" 납부명 ")]), _c('p', [_vm._v(" 은행, 인터넷 뱅킹 및 인터넷 지로 사이트 (www.giro.or.kr)를 통해 납입 ")])])])])], 1), _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-24 grey-fa"
  }, [_c('h5', {
    staticClass: "font-size-16 font-size-lg-20 line-height-1 mb-8 mb-lg-16"
  }, [_vm._v(" 위드캔복지재단 후원계좌 ")]), _c('p', {
    staticClass: "page-text page-text--sm line-height-15 mb-4 mb-lg-8"
  }, [_vm._v(" KB국민은행 027001-04-234141(비지정) 사회복지법인 위드캔복지재단 ")]), _c('p', {
    staticClass: "page-text page-text--sm line-height-15 mb-8 mb-lg-16"
  }, [_vm._v(" KB국민은행 017037-04-003957(지정) 사회복지법인 위드캔복지재단 ")]), _c('p', {
    staticClass: "page-text page-text--sm line-height-15",
    staticStyle: {
      "color": "#F19C6D"
    }
  }, [_vm._v(" (반드시 예금주를 확인하세요) ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }